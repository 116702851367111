import {Component, Inject, OnInit} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {Router} from '@angular/router';
import {translate, TranslocoModule} from '@jsverse/transloco';
import {marker} from '@jsverse/transloco-keys-manager/marker';
import {DeviceType, ExceededLicenseTO} from 'api/entities';
import {DialogTitleComponent} from '../../shared/components/dialog-title/dialog-title.component';
import {MessageComponent} from '../../shared/components/message/message.component';
import {DeviceService} from '../../shared/entities/devices/device.service';
import {orderByLocale} from '../../shared/services/order-by-locale.service';
import {ExceededLicenseEntriesInterface} from './exceeded-license-entries.interface';

@Component({
  selector: 'alc-limit-license',
  templateUrl: './limit-license.component.html',
  styleUrls: ['./limit-license.component.scss'],
  standalone: true,
  imports: [DialogTitleComponent, MatDialogModule, MessageComponent, MatButtonModule, MatTooltipModule, MatIconModule, TranslocoModule]
})
export class LimitLicenseComponent implements OnInit {
  public exceededLicenseEntries: ExceededLicenseEntriesInterface[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {exceededLicenseData: ExceededLicenseTO[]},
    private router: Router,
    public dialogRef: MatDialogRef<LimitLicenseComponent>,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.exceededLicenseEntries = this.getExceededDataPointsEntries(this.data.exceededLicenseData);
  }

  getExceededDataPointsEntries(data: ExceededLicenseTO[]): ExceededLicenseEntriesInterface[] {
    const result: Array<ExceededLicenseEntriesInterface> = [];
    const size = data.length;
    for (let i = 0; i < size; i++) {
      const exceededLicense = data[i];
      const exceededDataPoints = exceededLicense.usageCount - exceededLicense.packageSize;
      const exceededDataPointsLabel =
        exceededDataPoints > 1
          ? translate(marker('DATA_POINTS.LABEL.DATA_POINTS_COUNT'), {count: exceededDataPoints})
          : translate(marker('DATA_POINTS.LABEL.DATA_POINTS_ONE'));
      result.push({
        deviceName: exceededLicense.deviceName,
        deviceId: exceededLicense.deviceId,
        deviceType: exceededLicense.deviceType,
        packageLabel: exceededLicense.packageLabel,
        exceededDataPointsLabel
      });
    }
    return orderByLocale(result, 'deviceName');
  }

  goToDeviceDetails(deviceId: number, deviceType: DeviceType): void {
    this.dialogRef.close();
    this.deviceService.goToDevice({id: deviceId, model: deviceType}, true);
  }
}
